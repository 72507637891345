export default function AboutUs() {
    return (
        <section id="aboutus" className="faq">

            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    {/* <h2>Services</h2> */}
                    <p>About Us</p>
                </header>
                {/* <header className="section-header">
                    <h2>About Us</h2>
                </header> */}

                <div className="row">
                    <div className="col-lg-2">
                    </div>
                    <div className="col-lg-8">
                        <p>We are happy to announce that We are rebranding with SS Sarthi Financial Services where SS stands for <span className="red-highlight">Sanwaliya Seth - One of Avatar of Lord Krishna</span>. He would be our partner and Sarthi in this on going joirney to serve you at our best.</p>
                        <p>We at SS Sarthi Finacial Services thrive to deliver best services in market for our Financial Services bouquet covering: <span className="red-highlight">Mutual Funds, FD, Bounds, LIC, General Insurance and Property Valuation.</span></p>
                        <p>We are always eager to join customer's growth journey as <span className="red-highlight">Sarthi "Together Forever"</span> in individual's life by providing tailor made portfolios to achieve their financial goals like: Emergency Fund, Prosperous Retirement, Child Education, Child Marriage, Purchasing new Home/Vehicle, Any specific goals, Expansion in business etc., </p>
                    </div>
                    <div className="col-lg-2">
                    </div>
                </div>

            </div>

        </section >
    )
}
