import React, { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'

export default function Header() {

    const HeaderRef = useRef()
    const MenuTrigger = useRef()
    const navbar = useRef()

    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? HeaderRef.current.classList.add('header-scrolled') : HeaderRef.current.classList.remove('header-scrolled')
        }
        window.addEventListener("scroll", handleScroll, { passive: true });


        MenuTrigger.current.addEventListener('click', () => {
            if (navbar.current.classList.contains("navbar-mobile")) {
                navbar.current.classList.remove("navbar-mobile")
                MenuTrigger.current.classList.remove("bi-x")
                MenuTrigger.current.classList.add("bi-list")
            } else {
                navbar.current.classList.add("navbar-mobile")
                MenuTrigger.current.classList.add("bi-x")
                MenuTrigger.current.classList.remove("bi-list")
            }
        })

        document.querySelectorAll(".open-child").forEach(el => {
            el.addEventListener("click", e => {
                console.log(e.target.parentNode.parentNode.querySelector("ul").classList.toggle("dropdown-active"));
            })
        })

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])


    return (
        <>
            <header id="header" ref={HeaderRef} className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <NavLink to="/" className="logo d-flex align-items-center">
                        <img src="assets/images/logo.png" alt="" />
                        {/* <span>SS Sarthi</span> */}
                    </NavLink>

                    <nav id="navbar" className="navbar" ref={navbar}>
                        <ul>
                            {/* <li><NavLink className="nav-link scrollto" activeClassName="active" to="/" exact>Home</NavLink></li>
                            <li className="dropdown"><NavLink to="/services"><span>Services</span> <i className="bi bi-chevron-down open-child"></i></NavLink>
                                <ul>
                                    <li>
                                        <NavLink to="/mutual-fund" activeClassName="active" >Mutual Funds</NavLink>
                                    </li>
                                    <li className="dropdown"><NavLink to="#"><span>Insurance</span> <i className="bi bi-chevron-down open-child"></i></NavLink>
                                        <ul>
                                            <li><NavLink to="/life-insurance">Life Insurance</NavLink></li>
                                            <li><NavLink to="/general-insurance">General Insurance</NavLink></li>
                                        </ul>
                                    </li>
                                    <li><a href="https://agriculturallandvaluer.in/">Property Valuation</a></li>
                                    <li className="dropdown"><NavLink to="/"><span>Taxation</span> <i
                                        className="bi bi-chevron-down open-child"></i></NavLink>
                                        <ul>
                                            <li><NavLink to="#">IT Return Filing</NavLink></li>
                                            <li><NavLink to="#">Tax/Audit Consulting</NavLink></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> */}
                            <li><a className="getstarted" href="tel:8000383222">Call Now</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" ref={MenuTrigger}></i>
                    </nav>
                </div>
            </header>
        </>
    )
}
