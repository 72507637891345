import axios from "axios";
import { useState } from 'react'

export default function Hero() {
    const initialState = { firstname: '', lastname: '', email: '', message: '' }
    const [form, setForm] = useState(initialState)

    const handleChange = (e) => setForm({
        ...form,
        [e.target.name]: e.target.value
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const $form = e.target

        axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/20112200/73a5c01a-642f-4360-ac5b-2cd9008f8b8f`, {
            "fields": [
                {
                    "name": "firstname",
                    "value": form.firstname
                }, {
                    "name": "lastname",
                    "value": form.lastname
                }, {
                    "name": "email",
                    "value": form.email
                }, {
                    "name": "message",
                    "value": form.message
                }
            ],
            "context": {
                "pageUri": document.location.href,
                "pageName": document.title
            }
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                $form.innerHTML = res.data.inlineMessage
            }
        }).catch(error => console.log(error))
    }
    return (
        <>
            <section id="hero" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h4 className="text-center">Inquiry Form</h4>
                            <br />
                            <form onSubmit={handleSubmit} className="inquiry">
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <input type="text" name="firstname" className="form-control" placeholder="First Name" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" name="lastname" placeholder="Last Name" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="email" className="form-control" name="email" placeholder="Your Email" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea className="form-control" name="message" rows="6" placeholder="Message" onChange={handleChange} required></textarea>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                            {/* <h1 data-aos="fade-up">FPIs net sellers for 2nd consecutive month; pull out Rs 1,730 crore in May</h1>
                            <h2 data-aos="fade-up" data-aos-delay="400">We are team of talented designers making websites with Bootstrap
                            </h2>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div className="text-center text-lg-start">
                                    <a href="#about"
                                        className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-6 hero-img row justify-content-center" data-aos="zoom-out" data-aos-delay="200">
                            {/* <img src="assets/img/hero-img.png" className="img-fluid" alt=""> */}
                            <div className="">
                                <h4 className="text-center">Login into Portal</h4>
                                <br />
                                <form method="post" action="https://iinvestoffice.com//chklogin.aspx" id="form1" runat="server">
                                    <div className="row gy-4">
                                        <div className="col-md-12">
                                            <select name="drp_Logintype" id="drp_Logintype" className="form-select">
                                                <option value="">Choose Option</option>
                                                <option value="Admin">Back Office</option>
                                                <option value="C">Client</option>
                                            </select>
                                        </div>
                                        <input name="DistributorCode" type="hidden" id="DistributorCode" runat="server" className="form-control"
                                            value="S0775" />
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="txtdpid" id="txtdpid" placeholder="User Name" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="Password" className="form-control" name="txtPassword" id="txtPassword"
                                                placeholder="Password" />
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit"
                                                className="btn btn-login d-inline-flex align-items-center justify-content-center align-self-center">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
