import React from 'react'
import About from '../components/About'
import AboutUs from '../components/AboutUs'
import Faq from '../components/Faq'
import Hero from '../components/Hero'
import Services from '../components/Services'

export default function Home() {
    return (
        <>
            <Hero />
            <main id="main">
                <AboutUs />
                <About />
                <Services />
                <Faq />
            </main>
        </>
    )
}
