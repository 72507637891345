import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer id="footer" className="footer">
            {/* <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h4>Our Newsletter</h4>
                            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                        </div>
                        <div className="col-lg-6">
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            <Link to="/" className="logo d-flex align-items-center">
                                <img src="assets/images/logo.png" alt="" />
                                {/* <span>SS</span> */}
                            </Link>
                            <p>Team of advisors with experience of more than 10 years for Insurance, Mutual Funds solutions</p>
                            <div className="social-links mt-3">
                                <Link to="/" className="twitter"><i className="bi bi-twitter"></i></Link>
                                <Link to="/" className="facebook"><i className="bi bi-facebook"></i></Link>
                                <Link to="/" className="instagram"><i className="bi bi-instagram bx bxl-instagram"></i></Link>
                                <Link to="/" className="linkedin"><i className="bi bi-linkedin bx bxl-linkedin"></i></Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-6">
                            <div className="info-box">
                                <h4>Open Hours</h4>
                                <p>Monday - Friday<br />9:00AM - 06:00PM</p>
                                <p>Saturday<br />11:00AM - 02:00PM</p>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Calculators</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Mutual Fund</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">FD/Bonds</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Property Valuation</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Insurance</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Taxation</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">Retirement Planning</Link></li>
                            </ul>
                        </div> */}

                        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            <p>Sheetal Suthar <strong><a href="tel:+918000383222">+91 8000 383222</a></strong><br />
                                Siddharth Pandya <strong><a href="tel:+918141316000">+91 8141 316000</a></strong><br />
                                <strong><a href="mailto:hello.ss.sarthi15@gmail.com">hello.ss.sarthi15@gmail.com</a> <br /></strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright"><strong><span>SS Sarthi Financial Services</span></strong></div>
            </div>
        </footer >
    )
}
