import React from 'react'

export default function MutualFund() {
    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Mutual Funds</li>
                    </ol>
                    <h2>Mutual Funds</h2>
                </div>
            </section>

            <section className="inner-page">
                <div className="container">
                    <h5 className="fw-bold">What is Mutual Fund?</h5>
                    <ul>
                        <li>A mutual fund is a type of investment vehicle consisting of a portfolio of stocks, bonds, or other
                            securities</li>
                        <li>Mutual funds give small or individual investors access to diversified, professionally managed portfolios
                            at a low price</li>
                        <li>Mutual funds are divided into several kinds of categories, representing the kinds of securities they
                            invest in, their investment objectives, and the type of returns they seek
                        </li>
                        <li>Mutual funds charge annual fees (called expense ratios) and, in some cases, commissions, which can affect
                            their overall returns</li>
                        <li>The overwhelming majority of money in employer-sponsored retirement plans goes into mutual funds</li>
                    </ul>

                    <h5 className="fw-bold">Advantages of MF</h5>
                    <ul>
                        <li>Mutual funds offer diversification or access to a wider variety of investments than an individual investor
                            could afford to buy</li>
                        <li>There are economies of scale in investing with a group</li>
                        <li>Monthly contributions help the investor's assets grow</li>
                        <li>Funds are more liquid because they tend to be less volatile</li>
                        <li>The investor gets professional investment management services</li>
                    </ul>

                    <h5 className="fw-bold">NAV</h5>
                    <ul>
                        <li>Net asset value (NAV) represents a fund's per share market value</li>
                        <li>NAV is calculated by dividing the total value of all the cash and securities in a fund's portfolio, minus
                            any liabilities, by the number of outstanding shares</li>
                        <li>The NAV calculation is important because it tells us how much one share of the fund is worth</li>
                    </ul>

                    <h5 className="fw-bold">SIP: Systematic Investment Plan - The best way to invest in mutual funds</h5>
                    <ul>
                        <li>Invest in small amounts regularly</li>
                        <li>Opt for monthly auto-payment</li>
                        <li>No worry for market ups & downs</li>
                        <li>Plan budgets and expenses better</li>
                    </ul>
                    <h5 className="fw-bold">Lump-Sum</h5>
                    <ul>
                        <li>It is a one-time investment you make, like say, Rs 5,00,000. If you have a substantial disposable amount
                            in hand and have a higher risk tolerance, then you may opt for making a lump-sum investment.</li>
                    </ul>
                    <h5 className="fw-bold">Customised/Trailor made portfolio</h5>
                    <ul>
                        <li>Every investor has distinct investment needs, different investment horizons and contrasting risk taking
                            abilities. So, every investor should have a customised portfolio depending on his or her individual criteria
                            rather than having a model portfolio built on some common criteria.
                        </li>
                        <li>Our in-house developed algorithm helps an investor build his own customised Mutual Fund portfolio based on
                            the following variables:</li>
                        <ul>
                            <li>Investment horizon of the investor</li>
                            <li>Risk taking ability of the investor</li>
                            <li>Current valuations of the equity markets</li>
                        </ul>
                    </ul>
                </div>
            </section>

        </main>
    )
}
