import React, { useEffect } from 'react'

export default function Faq() {
    useEffect(() => {

        document.querySelectorAll(".accordion-button").forEach(el => {
            el.addEventListener('click', e => {
                const $this = e.target
                if ($this.classList.contains("collapsed")) {
                    $this.classList.remove("collapsed")
                    $this.parentNode.parentNode.querySelector(".accordion-collapse").classList.remove("collapse")
                } else {
                    $this.classList.add("collapsed")
                    $this.parentNode.parentNode.querySelector(".accordion-collapse").classList.add("collapse")
                }
            })
        })

    }, [])
    return (
        <section id="faq" className="faq">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <h2>F.A.Q</h2>
                    <p>Frequently Asked Questions</p>
                </header>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="accordion accordion-flush" id="faqlist1">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-1">
                                        When should I start investing in Mutual Funds?
                                    </button>
                                </h2>
                                <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        There is no reason why one should delay one’s investments, except, of course, when there is no money to invest. Within that, it is always better to use Mutual Funds than to do-it-oneself.

                                        There is no minimum age when one can start investing. The moment one starts earning and saving, one can start investing in Mutual Funds. In fact, even kids can open their investment accounts with Mutual Funds out of the money they receive once in a while in form of gifts during their birthdays or festivals. Similarly, there is no upper age for investing in Mutual Funds.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-2">
                                        How do Mutual Funds help manage risk?
                                    </button>
                                </h2>
                                <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        Risks appear in many forms. For example, if you own a share of a company, there is a Price Risk or a Market Risk or a Company Specific Risk. The share of just that company may dip or even crash due to any of the above reasons or even a combination of these reasons.

                                        However, in a Mutual Fund, a typical portfolio holds many securities, thus offering “diversification”. In fact, diversification is one of the biggest benefits of investing in a Mutual Fund. It ensures that the dip in price of one or even a few securities does not affect portfolio performance alarmingly.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-3">
                                        Which Mutual Fund should I choose for mid-term investment?
                                    </button>
                                </h2>
                                <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        4-6 years is considered medium-term in savings and investment decisions and hence capital appreciation should be your objective here. Corporate bond funds and hybrid funds are best suited for capital appreciation as they are less volatile compared to equity funds which are ideal for wealth creation over long-term. Corporate bond funds invest in high quality bonds with 3-5 years average maturity, becoming less sensitive to interest rate changes. Hybrid funds invest predominantly in debt with some equity exposure thus providing a safer investment option with potential for capital appreciation.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="accordion accordion-flush" id="faqlist2">

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-1">
                                        Are Mutual Funds offered by Banks?
                                    </button>
                                </h2>
                                <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Banks are in the business of savings and loans while Mutual Funds are for investments. When you put your money in a savings account or in a fixed deposit, you are making savings whereas when you put your money in Mutual Funds, you are making investments. Banking and Mutual Funds are two completely different businesses, requiring specific domain and organizational expertise. Banks are governed by RBI while Mutual Funds are regulated by SEBI. If some corporate wants to be in the business of banking and Mutual Funds, it must seek separate license permits from the respective regulators and run these two businesses as different companies.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-2">
                                        Choosing a Mutual Fund is too confusing?
                                    </button>
                                </h2>
                                <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Yes, there are several types of Mutual Fund schemes – Equity, Debt, Money Market, Hybrid etc. And there are many Mutual Funds   in India managing several hundreds of schemes amongst them. So it may appear that zeroing on a scheme is actually a very complex and confusing affair.

                                        Choosing the scheme to invest in should be the last thing in an investor’s mind. There are several more important steps before that, which will help remove much confusion later.

                                        An investor should first of all have an investment objective, say retirement planning or renovating one’s house.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-3">
                                        What happens when you miss SIP payments in-between?
                                    </button>
                                </h2>
                                <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Many investors worry about loss in Mutual Funds if they are unable to make SIP payments during its tenure. Such situations can arise due to many reasons like you are undergoing some financial difficulty or uncertainty about job or business income. It’s natural that under such situations you may not be able to continue with your regular SIP payments. Since SIPs are a long-term investment option, it’s fine if you skip a few payments in-between. Unlike an insurance policy where non-payment of annual premium can lead to policy inactivation, here your investments made so far will continue to earn a return and you can withdraw it anytime. However, you would accumulate lower wealth than what you had initially expected and may miss your financial goals if you are too irregular
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </section>
    )
}
