import React from 'react'

export default function Services() {
    return (
        <section id="features" className="features">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <h2>Services</h2>
                    <p>Services offered by Us</p>
                </header>
                <div className="row">
                    <div className="col-lg-6">
                        <img src="assets/img/features.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                        <div className="row align-self-center gy-4">
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Mutual Fund</h3>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>FD/Bonds</h3>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Property Valuation</h3>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Insurance</h3>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Taxation</h3>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Retirement Planning</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
