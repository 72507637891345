import React from 'react'

export default function About() {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                        <div className="content">
                            <h3>Who We Are</h3>
                            {/* <h2>Expedita voluptas omnis cupiditate totam eveniet nobis sint iste. Dolores est repellat corrupti
                                reprehenderit.</h2>  */}
                            <h2>Team of advisors with experience of more than 10 years for Insurance, Mutual Funds solutions</h2>
                            <p>Get consultation and solutions for your desired future FINANCIAL GOALS from team of experts and plan
                                your future for WEALTHY LIFE</p>
                            <p>Get competitive premium quotations for your Insurance requirement from ALL leading insurance companies
                                of India</p>
                            <p>Expert panel of Valuers for all kind of Properties Valuation</p>
                            {/* <div className="text-center text-lg-start">
                                <a href="#"
                                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>Read More</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>  */}
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                        <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
