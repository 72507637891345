import React from 'react'

export default function GeneralInsurance() {
    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>General Insurance</li>
                    </ol>
                    <h2>General Insurance</h2>
                </div>
            </section>

            <section className="inner-page">
                <div className="container">
                    <p>General insurance or non-life insurance policies, including automobile and homeowners’ policies, provide payments depending on the loss from a particular financial event. General insurance is typically defined as any insurance that is not determined to be life insurance.
                    </p>
                    <h5 className="fw-bold">Vehicle Insurance – Two/Four-Wheeler</h5>
                    <ul>
                        <li>Your damages are covered: Imagine running your vehicle into another car accidentally. Repairing frontal damages such as the bonnet, bumper damages, windshield damages and headlights may cost you easily around 50,000 to 60,000. Can you imagine paying that out of your pocket? A motor insurance helps you take care of such unexpected expenses.</li>
                        <li>You are protected with third party insurance cover: The third party motor insurance cover is mandatory in India. It covers any damage caused to a third party due to your car.
                        </li>
                        <li>You can opt for a Personal Accident Add on Cover: As part of the Optional Personal Accident cover, you can opt for a motor insurance cover that covers accidents anywhere in the world regardless of who is driving the car. Under this cover, you are insured against Accidental Death and Permanent Total Disablement.</li>
                    </ul>
                    <h5 className="fw-bold">Medical/Health Insurance</h5>
                    <ul>
                        <li>Health Insurance is a type of insurance that offers coverage to the policy holder for medical expenses in case of a health emergency </li>
                        <li>A health insurance plan chosen by the insured provides coverage for different expenses including surgical expenses, day-care expenses, and critical illness etc.
                        </li>
                    </ul>
                    <h5 className="fw-bold">Home Insurance</h5>
                    <ul>
                        <li>Home insurance offers coverage to a house and its content from unforeseen circumstances such as damages caused by natural calamities (earthquake, fire, flood, storm, landslide, etc.) and man-made activities (theft, burglary, terrorism, riot, etc.).</li>
                    </ul>
                    <h5 className="fw-bold">Travel Insurance    </h5>
                    <ul>
                        <li>Travel insurance is an insurance product for covering unforeseen losses incurred while travelling, either internationally or domestically.</li>
                    </ul>
                </div>
            </section>

        </main>
    )
}
