import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './Pages/Home'
import MutualFund from './Pages/MutualFund'
import ScrollToTop from './utils/ScrollToTop';
import LifeInsurance from './Pages/LifeInsurance';
import GeneralInsurance from './Pages/GeneralInsurance';


export default function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop>
        <Switch>
          <Route path="/mutual-fund" component={MutualFund} />
          <Route path="/life-insurance" component={LifeInsurance} />
          <Route path="/general-insurance" component={GeneralInsurance} />
          <Route path="/" component={Home} exact />
        </Switch>
      </ScrollToTop>
      <Footer />
    </Router >
  )
}
