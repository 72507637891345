import React from 'react'

export default function LifeInsurance() {
    return (
        <main id="main">
            <section className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Life Insurance</li>
                    </ol>
                    <h2>Life Insurance</h2>
                </div>
            </section>

            <section className="inner-page">
                <div className="container">
                    <p>There is a wide range of benefits of owning life insurance:</p>
                    <ul>
                        <li>Financial safety for family and loved ones</li>
                        <li>High life cover at affordable premiums</li>
                        <li>Tax benefits under section 80C and section 10(10D) of Income tax act, 1961</li>
                        <li>Tax free payout subject to condition mentioned under section 10(10D) of Income Tax Act, 1961.</li>
                        <li>Assured income through Annuity plans</li>
                    </ul>

                    <h5 className="fw-bold">Term Life Insurance</h5>
                    <ul>
                        <li>Term insurance is a life insurance policy that provides financial coverage to the beneficiary of the policy if the life insured dies during the active term of the policy </li>
                        <li>A term insurance plan provides life insurance cover against the fixed premium paid for specified "term" of the year. As a pure protection plan, term insurance policies offer higher insurance coverage at lower premium rates</li>
                    </ul>
                    <h5 className="fw-bold">Money Back Plan</h5>
                    <ul>
                        <li>The money-back policy from Life Insurance Corporation in India is a popular insurance policy</li>
                        <li>It provides life coverage during the term of the policy and the maturity benefits are paid in installments by way of survival benefits in every 5 years</li>
                        <li>The plan is available with 20 years and 25 years term</li>
                    </ul>
                    <h5 className="fw-bold">Unit Linked Plan</h5>
                    <ul>
                        <li>Unit Linked Insurance Plans (ULIPs) are a category of goal-based financial solutions that offer dual benefits of protection and Investment</li>
                        <li>Your Unit linked Insurance Plan is linked to the capital market and offers you flexibility to invest your units in equity or debt funds depending upon your risk appetite</li>
                    </ul>
                    <h5 className="fw-bold">Pension/Retirement Plans</h5>
                    <ul>
                        <li>Retirement Plans are a category of life insurance plans that are specially designed to meet your post-retirement needs such as medical and living expenses</li>
                        <li>So that you can enjoy your golden years with financial independence, these policies help you plan for your expenses and secure your future</li>
                    </ul>
                </div>
            </section>

        </main>
    )
}
